<template>
    <div class="signUp_page">
        <Banner :adCategory="8"></Banner>
        <div class="module1">
            <div class="content">
                <div class="title display">为什么要学？</div>
                <div class="learning flex">
                    <div class="list pointer" :class="activeIndex == index ? 'active' : ''"
                        v-for="(item, index) in formData.applyWhyStudyVOS" :key="index" @mouseover="mouseOver(index)"
                        @mouseleave="activeIndex = null">
                        <img :src="item.whyStudyUrl" alt="" />
                        <div class="cont flex-center column">
                            <div class="text">{{ item.whyStudyName }}</div>
                            <span class="line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <div class="title display">线上+线下双向教学，助力学员快速掌握</div>
                <div class="teaching flex flex-wrap">
                    <div class="teaching_list pointer" v-for="(item, index) in formData.applyOnlineOfflineTeachingVOS"
                        :key="index" @mouseover="teachingMouseOver(index)" @mouseleave="teachingIndex = null">
                        <img :src="item.imageUrl" alt="" />

                        <div class="mask_layer display" v-if="teachingIndex == index">
                            <div class="mask_layer_cont">
                                {{ item.introduction }}
                            </div>
                        </div>
                        <div class="teaching_cont display" v-else>{{ item.name }}</div>
                    </div>
                </div>
                <div class="btn display pointer" @click="service">我要提问</div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <div class="title display">选择果尔佳的四个理由</div>
                <div class="reason_box flex">
                    <div class="list flex-center column" v-for="(item, index) in formData.applyReasonsChooseGuoerjiaVOS"
                        :key="index" @mouseover="reasonMouseOver(index)" @mouseleave="reasonIndex = null">
                        <img class="icon_img" :src="item.icon" alt="" />
                        <div class="reason">{{ item.reasonsName }}</div>
                        <div class="mask_layer display column" v-if="reasonIndex == index">
                            <p class="p1 display">{{ item.introduction }}</p>
                            <!-- <p class="p2 display">
                                16年品牌实力背书，行业经验丰富
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <div class="freeClasses">
                    <FreeClasses :imgUrl="imgUrl"></FreeClasses>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import WARBLER from '@/http/yingyu'
import Banner from '@/components/banner'
import FreeClasses from '@/components/freeClasses.vue'
export default {
    components: {
        Banner,
        FreeClasses,
    },
    data() {
        return {
            activeIndex: null,
            teachingIndex: null,
            reasonIndex: null,
            imgUrl: require('@/assets/img/signUp/bg.png'),
            learningList: [{}, {}, {}, {}],
            reasonList: [{}, {}, {}, {}],
            formData: {},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.api.findApplyInfo(this.params).then((res) => {
                this.formData = res.data
            })
        },
        mouseOver(index) {
            this.activeIndex = index
        },
        teachingMouseOver(index) {
            this.teachingIndex = index
        },
        reasonMouseOver(index) {
            this.reasonIndex = index
        },
        // 我要提问

        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.signUp_page {
    .title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        padding-top: 98px;
        margin-bottom: 50px;
    }

    .module1 {
        height: 708px;

        .learning {
            .list:nth-child(1) {
                margin-left: 0px !important;
            }

            .active {
                background: #003c7e !important;

                .text {
                    color: white !important;
                }
            }

            .list {
                width: 280px;
                height: 420px;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                padding: 4px 5px;
                box-sizing: border-box;
                margin-left: 23px;

                img {
                    width: 270px;
                    height: 260px;
                }

                .cont {
                    height: 156px;
                    padding-top: 39px;
                    box-sizing: border-box;

                    .text {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }

                    .line {
                        width: 73px;
                        height: 2px;
                        background: #ff792b;
                        margin-top: 7px;
                    }
                }
            }
        }
    }

    .module2 {
        height: 948px;
        background: #fafafa;

        .teaching_list:nth-child(3n + 1) {
            margin-left: 0px !important;
        }

        .teaching_list {
            width: 378px;
            height: 258px;
            position: relative;
            margin-left: 32px;
            margin-bottom: 30px;

            .mask_layer {
                position: absolute;
                top: 0;
                left: 0;
                width: 378px;
                height: 258px;
                background: #000;
                opacity: 0.7;

                .mask_layer_cont {
                    width: 270px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 30px;
                }
            }

            img {
                width: 378px;
                height: 258px;
            }

            .teaching_cont {
                width: 378px;
                height: 44px;
                background: #000;
                position: absolute;
                left: 0;
                bottom: 0;
                opacity: 0.8;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
            }
        }

        .btn {
            width: 200px;
            height: 50px;
            background: #ff881e;
            border-radius: 5px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 60px;
        }
    }

    .module3 {
        height: 607px;

        .reason_box {
            .list:nth-child(1) {
                margin-left: 0px !important;

                .icon_img {
                    width: 86px;
                    height: 86px;
                    margin-top: 38px;
                    margin-bottom: 33px;
                }
            }

            .list:nth-child(2) {
                .icon_img {
                    width: 71px;
                    height: 77px;
                    margin-top: 48px;
                    margin-bottom: 33px;
                }
            }

            .list:nth-child(3) {
                .icon_img {
                    width: 76px;
                    height: 73px;
                    margin-top: 51px;
                    margin-bottom: 41px;
                }
            }

            .list:nth-child(4) {
                .icon_img {
                    width: 88px;
                    height: 74px;
                    margin-top: 51px;
                    margin-bottom: 39px;
                }
            }

            .list {
                width: 280px;
                height: 258px;
                background: #ffffff;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                margin-left: 26px;
                position: relative;

                .mask_layer {
                    width: 280px;
                    height: 258px;
                    background: #003c7e;
                    padding: 0px 18px;
                    position: absolute;
                    top: 0;
                    left: 0;

                    .p1 {
                        width: 162px;
                        height: 42px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 24px;
                        text-align: center;
                    }

                    .p2 {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 16px;
                        margin-top: 12px;
                        text-align: center;
                    }
                }

                .reason {
                    width: 170px;
                    height: 42px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }
    }

    .module4 {
        height: 450px;
        background: url('../../assets/img/signUp/bg1.png') no-repeat;
        background-size: 100% 100%;
        position: relative;

        .freeClasses {
            position: absolute;
            top: -63px;
            left: 50%;
            margin-left: -600px;
        }
    }
}</style>